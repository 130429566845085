<template>
  <div
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    v-if="show"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              class="modal__close modal__close--black"
              @click="closeModalFunction"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">re-assigned Task</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Assign To</label>
                  <multiselect
                    v-model="selectedCustomer"
                    id="ajax"
                    label="name"
                    track-by="name"
                    placeholder="Find Users"
                    open-direction="bottom"
                    :options="customers"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :limit-text="limitText"
                    :max-height="800"
                    :show-no-results="false"
                    :hide-selected="true"
                    :preserve-search="true"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        <span>{{ option.name }}</span>
                        <span class="custom__remove" @click="remove(option)"
                          >❌</span
                        >
                      </span>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="form__item">
              <div class="form__item">
                <label
                  class="form__label text--regular-alt text--bold text--black"
                  >notes</label
                >
                <textarea
                  cols="5"
                  rows="5"
                  class="input form__input"
                  placeholder=""
                  v-model="tasks.comment"
                ></textarea>
              </div>
            </div>

            <div class="form__item">
              <div class="form__action">
                <button
                  class="button form__button form__button--lg w-30 save-changes"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  data-dismiss="#maxWithdrawalModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click.prevent="reassignCurrentTask"
                  v-else
                >
                  Save Changes
                </button>
                <a
                  role="button"
                  data-dismiss="#maxWithdrawalModal"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeModalFunction"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import AccessApiService from "@/core/services/access.service";
export default {
  name: "reassignTask",
  props: {
    show: Boolean,
    close: Function,
    task: Object,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      tasks: {
        comment: "",
        taskKey: "",
        reassignedToUserKey: "c1923beea35641d690e47c23b3f5d7f9",
        reassignedByUserKey: "",
      },
      loading: false,
      allTaskType: [
        { name: "Customer" },
        { name: "Group" },
        { name: "Loan Product" },
        { name: "Saving Product" },
        { name: "Centre" },
        { name: "Branch" },
      ],
      taskType: "",
      customers: [],
      customer: {
        pageIndex: 1,
        pageSize: 1000000,
      },
      selectedCustomer: {},
    };
  },
  computed: {
    gettaskKey() {
      return this.task.taskKey;
    },
    getReassignedByUserKey() {
      return this.task.assignedToKey;
    },
  },
  methods: {
    resetForm() {
      this.selectedCustomer = {};
      this.tasks.comment = "";
    },
    closeModalFunction() {
      this.resetForm();
      this.$emit("close");
    },
    reassignCurrentTask() {
      this.loading = true;
      ApiService.post("TaskManagement/reassigntask", {
        taskKey: this.gettaskKey,
        comment: this.tasks.comment,
        reassignToUserKey: this.selectedCustomer.userKey,
        reassignByUserKey: this.getReassignedByUserKey,
      })
        .then((response) => {
          if (response.data.status !== false) {
            this.loading = false;
            this.$emit("task-reassigned-successfully", response.data.message);
            this.$emit("close");
          } else {
            this.loading = false;
            this.$emit("error-reassigning-task", response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-reassigning-task", error.response.data.message);
        });
    },
    limitText(count) {
      return `and ${count} other customers`;
    },
    changeSearchParams() {
      if (this.taskType === "Customer") {
        this.getCustomers();
      } else if (this.taskType === "Group") {
        this.getGroups();
      }
    },
    userName({ userLastName }) {
      return `${userLastName}`;
    },
    getUsers() {
      const page = {
        pageNumber: 1,
        pageSize: 10000,
        status: true,
        filters: [],
      };
      AccessApiService.post("ProleanUser/GetUsersWithoutPermission", page).then(
        (response) => {
          this.customers = response.data.data;
        }
      );
    },
    getGroups() {
      GeneralApiService.post("Group/Groups", this.customer).then((resp) => {
        this.customers = resp.data.data.items;
      });
    },
    apifetch(query) {
      GeneralApiService.get(`Customer/search/${query}`).then((resp) => {
        this.customers = resp.data.data.items;
      });
    },
    changeSelectedKey() {
      this.task.reassignedToUserKey = this.selectedCustomer.customerKey;
    },
  },
  created() {
    this.getUsers();
  },
};
</script>
